import {
	thunk as originThunk,
	reducer,
	persist,
	thunkOn,
	debug,
	createTypedHooks,
	createTransform,
	createStore,
	createContextStore,
	actionOn,
	computed,
	action,
	useStoreState,
	useStoreActions,
	generic,
	useLocalStore,
	useStore,
	useStoreRehydrated,
	useStoreDispatch,
	StoreProvider,
	// eslint-disable-next-line import/no-unresolved
} from 'easy-peasy-origin'

/**
 * @typedef {Object} Helpers
 * @property {function} dispatch
 * @property {function} getState
 * @property {function} getStoreState
 * @property {function} getStoreActions
 * @property {Object} injections
 * @property {meta} injections
 */

/**
 * @callback thunkCallback
 * @param {Object} state
 * @param {Object} payload
 * @param {Helpers} Helpers
 */

/**
 * @param {thunkCallback} callback
 */
const thunk = callback =>
	// eslint-disable-next-line func-names
	originThunk(async function () {
		// eslint-disable-next-line prefer-rest-params
		const [, payload, { dispatch }] = arguments

		try {
			// eslint-disable-next-line prefer-rest-params
			const result = await callback(...arguments)
			return result
		} catch (e) {
			dispatch({
				type: this.actionCreator.failType,
				payload,
				error: e,
			})
			throw e
		}
	})

export {
	thunk,
	reducer,
	persist,
	thunkOn,
	debug,
	createTypedHooks,
	createTransform,
	createStore,
	createContextStore,
	actionOn,
	computed,
	action,
	useStoreState,
	useStoreActions,
	generic,
	useLocalStore,
	useStore,
	useStoreRehydrated,
	useStoreDispatch,
	StoreProvider,
}
